<template>
  <div class="py-4">
    <v-sheet
      class="pa-6 d-flex"
      color="white"
      elevation="4"
      rounded="lg"
      outlined
      shaped
    >
      <v-row no-gutters align-content="space-between">
        <v-col cols="12" class="py-1">
          <div
            v-if="
              (products && products[0]) || (currentTip.amount > 0 && showTip)
            "
          >
            <div class="pb-3" v-if="products && products[0]">
              <v-row
                v-for="(product, index) in groupedProducts"
                :key="index"
                class="pb-2 product-text"
                no-gutters
                justify="space-between"
              >
                <v-col cols="8">
                  <div class="text-start product-description">
                    {{ `${product.name} x${Number(product.quantity)}` }}
                    <ul v-for="(option, index) in product.options" :key="index">
                      <li>{{ option.name }} x {{ option.quantity }}</li>
                    </ul>
                  </div>
                </v-col>
                <v-col cols="4">
                  <div class="text-end">
                    {{ currencySymbol()
                    }}{{ formatPrice(product.unitPrice * product.quantity, 2) }}
                    <div
                      v-for="(option, index) in product.options"
                      :key="index"
                    >
                      {{ currencySymbol() }}
                      {{
                        formatPrice(
                          parseFloat(option.unitPrice) * option.quantity,
                          2
                        )
                      }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>

            <v-row
              v-if="currentTip.amount > 0 && showTip"
              class="pb-2 product-text"
              no-gutters
              justify="space-between"
            >
              <v-col cols="8">
                <div class="text-start product-description">Propina</div>
              </v-col>
              <v-col cols="4">
                <div class="text-end">
                  {{ currencySymbol() }}{{ formatPrice(currentTip.amount, 2) }}
                </div>
              </v-col>
            </v-row>

            <v-row
              v-if="hasToShowShippingCost"
              class="pb-2 product-text"
              no-gutters
              justify="space-between"
            >
              <v-col cols="8">
                <div class="text-start product-description">Envio</div>
              </v-col>
              <v-col cols="4">
                <div class="text-end">
                  {{ currencySymbol() }}
                  {{ formatPrice(getShippingCost, 2) }}
                </div>
              </v-col>
            </v-row>
          </div>

          <no-products-found v-else />
        </v-col>

        <v-col cols="12" class="pt-1">
          <v-divider></v-divider>
          <v-row
            class="pt-3 product-text font-weight-medium"
            no-gutters
            justify="space-between"
          >
            <v-col cols="8">
              <div class="text-start">{{ totalProductText }}</div>
            </v-col>
            <v-col cols="4">
              <div class="text-end">
                {{ currencySymbol() }}{{ formatPrice(productsTotal, 2) }}
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          v-if="!isValidAmount && getDeliveryMethod === 'delivery'"
        >
          <v-row class="pt-4 product-text" no-gutters justify="space-between">
            <v-col cols="12">
              <div class="text-start error--text">
                El monto mínimo para realizar el pedido es de
                {{
                  `${currencySymbol()} ${formatPrice(
                    this.minimumAmount.toString(),
                    2
                  )}`
                }}. Faltan
                {{
                  `${currencySymbol()} ${formatPrice(
                    this.differenceToMinimum.toString(),
                    2
                  )}`
                }}
                para poder realizarlo.
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import { formatPrice, currencySymbol, getProductPrice } from "@/utils/price.js";
import NoProductsFound from "@/components/NoProductsFound";
import shopUtils from "@/mixins/shopUtils";

export default {
  components: {
    NoProductsFound,
  },
  mixins: [shopUtils],
  data() {
    return {
      minimumAmount: this.$store.state.menu.profile.minimumAmount,
      differenceToMinimum: 0,
    };
  },

  props: {
    products: {
      type: Array,
      default: () => [],
    },
    showTip: {
      type: Boolean,
      default: false,
    },
    totalProductText: {
      type: String,
      default: "TOTAL",
    },
    currentTip: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    formatPrice,
    currencySymbol,
    getProductPrice,
  },
  computed: {
    hasToShowShippingCost() {
      return this.$store.getters["deliveryCart/shippingCost"] > 0;
    },
    getShippingCost() {
      return this.$store.getters["deliveryCart/shippingCost"];
    },
    getDeliveryMethod() {
      return this.$store.getters["deliveryCart/getDeliveryMethod"];
    },
    paymentStatus() {
      return this.$store.getters["order/paymentStatus"];
    },
    productsTotal() {
      let accumulator = 0;
      this.products.forEach((element) => {
        accumulator += parseFloat(element.unitPrice);

        if (element.options && element.options[0]) {
          accumulator += element.options.reduce(
            (previousValue, currentValue) =>
              previousValue +
              currentValue.quantity * Number(currentValue.unitPrice),
            0
          );
        }
      });

      if (accumulator >= this.minimumAmount) {
        this.$store.dispatch("deliveryCart/setMinimumAmountValid", true);
      } else {
        this.$store.dispatch("deliveryCart/setMinimumAmountValid", false);
      }

      accumulator += this.getShippingCost;

      if (
        this.currentTip.amount > 0 &&
        this.currentTip.status &&
        this.showTip
      ) {
        accumulator += this.currentTip.amount;
      }

      return accumulator;
    },
    groupedProducts() {
      const accumulator = {};

      this.products.forEach((element) => {
        if (element.sku == "generico") {
          element.sku += `_${element.unitPrice}`;
        }

        if (!accumulator[element.sku]) {
          accumulator[element.sku] = element;
          accumulator[element.sku].quantity = 0;
          accumulator[element.sku].quantity++;
        } else if (element?.options && element?.options?.length > 0) {
          const virtualSku = `${element.sku}_${Math.random()}`;
          accumulator[virtualSku] = element;
          accumulator[virtualSku].quantity = 1;
        } else {
          accumulator[element.sku].quantity++;
        }
      });
      return Object.values(accumulator);
    },
  },
  watch: {
    productsTotal: {
      deep: true,
      handler: function (newVal) {
        this.differenceToMinimum =
          this.minimumAmount -
          newVal +
          this.currentTip.amount +
          this.getShippingCost;
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.product-description
  max-width: 200px
.product-text
  font-size: 0.92rem !important
  overflow-wrap: break-word
  font-weight: 400
  letter-spacing: 0.02rem !important
</style>
