var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-section',{attrs:{"id":"menu-order-details","space":"0"}},[_c('v-container',{staticClass:"px-4"},[(_vm.products && _vm.products[0])?_c('v-slide-x-transition',{attrs:{"hide-on-leave":""}},[_c('div',[(
            !_vm.$route.meta.isDelivery &&
            (_vm.$store.getters['order/paidSortedExtendedProducts'].length > 0 ||
              _vm.currentTip.status === _vm.paymentStatus[2])
          )?_c('div',[_c('products-panel',{attrs:{"current-tip":_vm.currentTip,"total-product-text":"TOTAL PAGADO","show-tip":_vm.currentTip.status === _vm.paymentStatus[2],"products":_vm.$store.getters['order/paidSortedExtendedProducts']}}),(_vm.inProgressAndNotPaidProducts[0])?_c('div',[_c('div',{staticClass:"text-subtitle-1 primary--text font-weight-bold pt-5"},[_vm._v(" Resta Pagar: ")]),_c('products-panel',{attrs:{"current-tip":_vm.currentTip,"show-tip":_vm.currentTip.status !== _vm.paymentStatus[2],"products":_vm.inProgressAndNotPaidProducts}})],1):_vm._e()],1):_c('products-panel',{attrs:{"current-tip":_vm.currentTip,"show-tip":true,"products":!_vm.$route.meta.isDelivery
              ? _vm.$store.getters[
                  'order/notPaidAndPayInProgressSortedExtendedProducts'
                ]
              : _vm.inProgressAndNotPaidProducts}})],1)]):_c('base-section',{attrs:{"space":"0"}},[(_vm.loadingContent)?_c('v-row',{staticClass:"py-12",attrs:{"justify":"center"}},[_c('base-progress-circular')],1):_c('no-products-found')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }