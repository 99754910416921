<template>
  <base-section id="menu-order-details" space="0">
    <v-container class="px-4">
      <v-slide-x-transition v-if="products && products[0]" hide-on-leave>
        <div>
          <div
            v-if="
              !$route.meta.isDelivery &&
              ($store.getters['order/paidSortedExtendedProducts'].length > 0 ||
                currentTip.status === paymentStatus[2])
            "
          >
            <!-- Paid Products -->
            <products-panel
              :current-tip="currentTip"
              total-product-text="TOTAL PAGADO"
              :show-tip="currentTip.status === paymentStatus[2]"
              :products="$store.getters['order/paidSortedExtendedProducts']"
            />

            <!-- In Progress & Not Paid Products  -->
            <div v-if="inProgressAndNotPaidProducts[0]">
              <div class="text-subtitle-1 primary--text font-weight-bold pt-5">
                Resta Pagar:
              </div>
              <products-panel
                :current-tip="currentTip"
                :show-tip="currentTip.status !== paymentStatus[2]"
                :products="inProgressAndNotPaidProducts"
              />
            </div>
          </div>
          <!-- All Products -->
          <products-panel
            v-else
            :current-tip="currentTip"
            :show-tip="true"
            :products="
              !$route.meta.isDelivery
                ? $store.getters[
                    'order/notPaidAndPayInProgressSortedExtendedProducts'
                  ]
                : inProgressAndNotPaidProducts
            "
          />
        </div>
      </v-slide-x-transition>

      <base-section v-else space="0">
        <v-row v-if="loadingContent" justify="center" class="py-12">
          <base-progress-circular />
        </v-row>

        <no-products-found v-else />
      </base-section>
    </v-container>
  </base-section>
</template>

<script>
import ProductsPanel from "@/components/menu/order/details/ProductsPanel";
import NoProductsFound from "@/components/NoProductsFound";

export default {
  name: "MenuOrderDetailsSection",

  components: {
    ProductsPanel,
    NoProductsFound,
  },

  data: () => ({
    loadingContent: false,
  }),

  mounted: function () {
    this.fakeLoader();
  },

  computed: {
    products() {
      if (this.$route.meta.isDelivery) {
        return this.$store.state.deliveryCart.products;
      }
      return this.$store.state.order.products;
    },
    paymentStatus() {
      return this.$store.getters["order/paymentStatus"];
    },
    currentTip() {
      if (this.$route.meta.isDelivery) {
        return this.$store.state.deliveryCart.currentTip;
      }
      return this.$store.state.order.currentTip;
    },
    inProgressAndNotPaidProducts() {
      if (this.$route.meta.isDelivery) {
        return this.$store.getters["deliveryCart/sortedExtendedProducts"];
      }

      return [
        ...this.$store.getters["order/notPaidSortedExtendedProducts"],
        ...this.$store.getters["order/inProgressSortedExtendedProducts"],
      ];
    },
  },

  methods: {
    // Since products binding is event orientated and very quick,
    // a vuex loader variable isn't propagated effectively.
    fakeLoader() {
      this.loadingContent = true;
      const multipaymentUUID = window.localStorage.getItem("multipaymentUuid");
      if (multipaymentUUID) {
        this.$store.dispatch("order/resetMultipaymentUUID", multipaymentUUID);
      }
      setTimeout(() => {
        this.loadingContent = false;
      }, 1000);
    },
  },
};
</script>
